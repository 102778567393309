import io, { ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import config from '../../config.json'

export interface GatewayResult<D> {
  timestamp: number
  statusCode: number
  statusText?: string
  payloadType: 'javascript' | 'string' | 'number' | 'null'
  payload: D
}
export default class Gateway {
  private static readonly gatewaylist: { [key: string]: Socket } = {};

  public static init(params: {
    namespace: string;
    options?: Partial<ManagerOptions & SocketOptions>;
    errorCallback?: (e: Error) => void;
    disconnectCallback?: (r: Socket.DisconnectReason) => void;
  }) {
    const { namespace, options = {}, errorCallback, disconnectCallback } = params;
    if (!namespace || Gateway.gatewaylist[namespace]) return;

    options.extraHeaders ??= {};
    
    const url = config.backendGateway;
    options.path = config.backendGatewayPath;

    options.forceNew = true;
    options.reconnectionDelay = 5000;
    options.reconnectionAttempts = 10;

    if (!options.extraHeaders.authorization) {
      options.extraHeaders.authorization = ``;
    }

    Gateway.gatewaylist[namespace] = io(url + namespace, options);
    Gateway.gatewaylist[namespace].on('connect_error', e => {
      errorCallback?.(e);
    });
    Gateway.gatewaylist[namespace].on('disconnect', r => {
      disconnectCallback?.(r);
    });
  }

  public static on<T, E>(namespace: string, eventName: string, callback: (...event: T[]) => E) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return;
    Gateway.gatewaylist[namespace].on(eventName, callback);
  }

  public static once<T, E>(namespace: string, eventName: string, callback: (...event: T[]) => E) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return;
    Gateway.gatewaylist[namespace].once(eventName, callback);
  }

  public static off(namespace: string, eventName: string) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return;
    Gateway.gatewaylist[namespace].off(eventName);
  }

  public static emit<T>(namespace: string, eventName: string, ...args: T[]) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return 'error';
    Gateway.gatewaylist[namespace].emit(eventName, ...args);
    return 'ok';
  }

  public static close(namespace: string) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return;
    Gateway.gatewaylist[namespace].close();
    delete Gateway.gatewaylist[namespace];
  }

  public static event<T>(namespace: string, eventName: string, payload: Record<string, any>, callback: (result: GatewayResult<T>) => void) {
    if (!namespace.length || !Gateway.gatewaylist[namespace]) return 'error';
    const data = {
      timestamp: Date.now(),
      payload,
      callbackEvent: `event-${eventName}-${+new Date()}`,
    }

    Gateway.emit(namespace, eventName, data)
    Gateway.once(namespace, data.callbackEvent, (result: GatewayResult<T>) => {
      callback(result)
      Gateway.off(namespace, data.callbackEvent)
    })
    return 'ok';
  }
}
