import { observer } from "mobx-react";
import { useAuthStore, useAvitoStore } from "../app/stores";
import React, { useEffect } from "react";
import { getAvitoAccounts, refreshAvitoAccount } from "../shared";
import { Button, message, Modal, Popconfirm, Table } from "antd";
import { useLocation } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import { PacmanLoader } from "react-spinners";
import api from "../app/api";
import disableAvitoAccount from "../shared/api/disableAvitoAccount";
import enableAvitoAccount from "../shared/api/enableAvitoAccount";

const AvitoAccountList = observer(() => {
  const location = useLocation()

  const { setAccounts, accountsLoaded, accounts } = useAvitoStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (!accountsLoaded) {
      getAvitoAccounts({ token: user.token });
    }
  });

  const refreshAccount = async (id: number) => {
    const response = await refreshAvitoAccount(id);
    // const updatedAccounts = accounts
    //   .filter((e) => e.id !== id)
    //   .concat(response?.data);
    // setAccounts(updatedAccounts);

    if(response?.status === 201) message.success("Отправлен запрос на обновление. Аккаунт обновится в скором времени!")
    else {
      console.log(response)
      message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }
  };

  const onConfirm = async (id: number) => {
    const response = await disableAvitoAccount(id, user.token)

    const account = response?.data
    if(response?.status !== 200 || !account) {
      console.log(response)
      return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }

    message.success("Аккаунт успешно отключен");
    setAccounts(accounts.map(acc => {
      if(acc.id === id) acc.active = account.active
      return acc
    }));
  };

  const onEnable = async (id: number) => {
    const response = await enableAvitoAccount(id, user.token)

    if(response?.status === 400)return message.error("Аккаунт не валиден")
    if(response?.status === 403)return message.error("Пользователь не валиден")

    const account = response?.data
    if(response?.status !== 200 || !account) {
      console.log(response, account)
      return message.error("Что-то пошло не так. Обновите страницу и попробуйте снова!")
    }

    message.success("Аккаунт был успешно включен");
    setAccounts(accounts.map(acc => {
      if(acc.id === id) acc.active = account.active
      return acc
    }));
  }

  const [ loader, setLoader ] = React.useState(false)
  const [ isLogin, setIsLogin ] = React.useState<number | null>(null)

  async function loginAvitoAccount(id: number) {
    if(loader || isLogin)return

    setLoader(true)
    const result = await api.post(`amo/browser/login`, {
      avitoAccountId: id
    })

    setLoader(false)
    if(result.status === 200) {
      setIsLogin(id)
    }
    else {
      if(result.status === 404) {
        message.error("Аккаунт не найден! Обновите страницу");
      }
      else {
        message.error("Что-то пошло не так! Подробности в консоли разработчка");
        console.log(result)
      }
    }
  }
  async function loginAvitoAccountSubmit(id: number) {
    if(loader || !isLogin)return

    setLoader(true)
    const result = await api.post(`amo/browser/login/submit`, {
      avitoAccountId: id
    })

    setIsLogin(null)
    setLoader(false)

    if(result.status === 200) {
      message.success("Авторизация была пройдена.")
      setTimeout(() => {
        window.location = window.location
      }, 2000)
    }
    else if(result.status === 204) {
      message.error("Авторизация не была завершена. Повторите попытку")
    }
    else {
      if(result.status === 404) {
        message.error("Аккаунт не найден! Обновите страницу");
      }
      else if(result.status === 401) {
        message.error("Вы не запускали процесс авторизации авито аккаунта, либо он был прерван. Обновите страницу");
      }
      else {
        message.error("Что-то пошло не так! Подробности в консоли разработчка");
        console.log(result)
      }
    }
  }

  return (
    <div className={`mt-3`}>
      <Table columns={[
        { title: 'ID аккаунта', dataIndex: 'id', key: 'id' },
        { title: 'Номер аккаунта', dataIndex: 'client_number', key: 'client_number' },
        { title: 'Имя', dataIndex: 'name', key: 'name' },
        { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
        { title: 'Почта', dataIndex: 'email', key: 'email' },
        { title: 'Активность', dataIndex: 'active', key: 'active', render: (s: boolean) => (<div className={`w-[12px] h-[12px] ${!s ? 'bg-red-600' : 'bg-green-600'} rounded-full`}></div>) },
        { title: 'Ссылка', dataIndex: 'profile_url', key: 'profile_url', render: (t: string) => (<a href={t} className={`text-blue-400`} target={"_blank"}>{t.substring(0, 35)}...</a>) },
        { title: 'Дата добавления', dataIndex: 'created_at', key: 'created_at', render: (d: Date) => (new Date(d).toLocaleString()) },
        { title: 'Действия', dataIndex: 'actions', key: 'actions' },
      ]} dataSource={accounts.map((a, i) => {
        return {
          ...a,
          key: i,
          actions: (
            <div className={`flex gap-2 items-center`}>
              {a.active ? (
                <Popconfirm
                  title="Обновить аккаунт?"
                  description="Если аккаунт неактивен или возникли проблемы с доставкой, то обновите аккаунт"
                  onConfirm={() => refreshAccount(a.id)}
                  okText="Обновить"
                  cancelText="Отмена"
                >
                  <Button icon={<SyncOutlined />}></Button>
                </Popconfirm>
              ) : null}

              {a.active ? (
                <Popconfirm
                  title="Отключить аккаунт авито?"
                  description="При отключении аккаунта вы не сможете поддержать диалог с пользователями, которым отвечали с этого аккаунта"
                  onConfirm={() => onConfirm(a.id)}
                  okText="Отключить"
                  cancelText="Отмена"
                >
                  <Button danger style={{ width: 110 }}>Отключить</Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Включить аккаунт авито?"
                  description="При включении аккаунта мы проверим его на валидность и после включения Вы сможете получать сообщения"
                  onConfirm={() => onEnable(a.id)}
                  okText="Включить"
                  cancelText="Отмена"
                >
                  <Button style={{ width: 110 }}>Включить</Button>
                </Popconfirm>
              )}

              {a.active && !isLogin && location.hash.indexOf('_devmode') !== -1 ? (
                <Popconfirm
                  title={"Авторизовать авито аккаунт?"}
                  description={"После авторизации авито аккаунта будет доступна возможность загружать видео с amoCRM в авито чат\n\nВНИМАНИЕ!\nАвторизация авито аккаунта производится при помощи поддержки."}
                  onConfirm={() => loginAvitoAccount(a.id)}
                  okText={"Авторизовать"}
                  cancelText={"Отмена"}
                >
                  <Button>Login</Button>
                </Popconfirm>
              ) : ''}
            </div>
          )
        }
      })} />

      {loader && !isLogin ? (
          <div style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, .5)',
            top: '0',
            left: '0',
            zIndex: '500',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <PacmanLoader
              color={"#75a49a"}
              size={30}
            />
          </div>
        ) : null}
      {isLogin ? (
          <Modal title={"Ожидание..."} open={true} onOk={() => loginAvitoAccountSubmit(isLogin)} closable={false} footer={[
            <Button key={"back"} onClick={() => {
              setIsLogin(null)
            }}>
              Отмена
            </Button>,
            <Button key={"submit"} type={"primary"} onClick={() => loginAvitoAccountSubmit(isLogin)} loading={loader}>
              Подтвердить
            </Button>
          ]}>
            <p>Произведите вход в аккаунт авито.</p>
            <p>После успешного входа подтвердите действие, нажав "Подтвердить"</p>
          </Modal>
        ) : null}
    </div>
  )
});

export default AvitoAccountList;
