import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import { User } from "../../types";

class AuthStore {
  isAuth: boolean = false;
  user: User = {} as User;
  tgRegLink: string | null = null

  constructor() {
    makeObservable(this, {
      isAuth: observable,
      user: observable,
      tgRegLink: observable,
      setIsAuth: action.bound,
      setUser: action.bound,
      setTgLink: action.bound
    });
  }

  setIsAuth(b: boolean) {
    this.isAuth = b;
  }

  setUser(u: User) {
    this.user = u;
  }

  setTgLink(link: string) {
    this.tgRegLink = link
  }
}
export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const useAuthStore = () => useContext(AuthStoreContext);

